import React from 'react'
import styles from "./History.module.css";

const History = ({history}) => {
  console.log(history);
  return (
      <div className={styles.History}>
        <table>
          <tr>
            <th>Hash</th>
            <th>Card</th>
            <th>Power Rating</th>
            <th>Stamina</th>
            <th>Level</th>
            <th>Rewards</th>
            <th>XP</th>
            <th>Position</th>
          </tr>
          {history.map(row => {
            return (
                <tr>
                  <td key={row.hash}>{row.hash}</td>
                  <td key={row.hash+'-name'}>{row.cardName}</td>
                  <td key={row.hash+'-power'}>{row.power}</td>
                  <td key={row.hash+'-stamina'}>{row.stamina}</td>
                  <td key={row.hash+'-level'}>{row.level}</td>
                  <td key={row.hash+'-rewards'}>{row.rewards}</td>
                  <td key={row.hash+'-xp'}>{row.xp}</td>
                  <td key={row.hash+'-position'}>{row.position}</td>
                </tr>
            )
          })}
        </table>
      </div>
  )
}

export default History;