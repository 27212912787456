import React from 'react'
import styles from "./Rewards.module.css";

const Rewards = ({rewards}) => {
  console.log('Rewards :', Object.values(rewards.Rewards.Q));
  const aliceTags = Object.keys(rewards.Results.alices)
  return (
      <div className={styles.Rewards}>
        <table>
            <tr>
                <table className={styles.table1}>
                    <th><div className={styles.headingInfo}>Results</div>
                        <tr>
                            <td>Tile : {rewards.Results.tile}</td>
                        </tr>
                        <tr>
                            <td>Number : {rewards.Results.number}</td>
                        </tr>
                        <tr>
                            <td>Tx Hash : {rewards.Results.Tx_hash}</td>
                        </tr>
                    </th>
                    <th><div className={styles.headingInfo}>Alices</div>
                            {aliceTags.map(tag => {
                                return rewards.Results.alices[tag].map(cardId => {
                                    console.log(cardId)
                                    return(
                                        <tr>
                                        <td>
                                            {cardId} <i>{tag}</i>
                                        </td>
                                        </tr>
                                    )
                                })
                            })}
                    </th>
                    <th><div className={styles.headingInfo}>Queens</div>
                        {rewards.Results.queens.map(cardId => {
                            return(
                                <tr>
                                    <td>{cardId}</td>
                                </tr>
                            )
                        })}
                    </th>
                </table>
            </tr>
            <tr>
                <table className={styles.table2}>
                    <th><div className={styles.headingInfo}>Rewards</div>
                        <tr>
                            <td>QueensRewards : {rewards.Rewards.QueensRewards}</td>
                        </tr>
                        <tr>
                            <td>AliceRewards : {rewards.Rewards.AlicesRewards}</td>
                        </tr>
                        <tr>
                            <td>Card reward : <i>{Object.values(rewards.Rewards.C)[0].SHROOM}</i>
                                              <i>{Object.values(rewards.Rewards.C)[0].xp}</i>
                            <br />({Object.keys(rewards.Rewards.C)})
                            </td> 
                        </tr>
                    </th>
                    <th><div className={styles.headingInfo}>Alices (SHROOMS, XP)</div>
                        {Object.entries(rewards.Rewards.A).map(
                            ([key, value]) => {
                                return (
                                    <tr>
                                        <td>{key} : &nbsp; <i>{value.SHROOM}</i>&nbsp; <i>{value.xp}</i></td>
                                    </tr>
                                )
                            }
                        )}
                    </th>
                    <th><div className={styles.headingInfo}>Queens (SHROOMS, XP)</div>
                        {Object.entries(rewards.Rewards.Q).map(
                            ([key, value]) => {
                                return (
                                    <tr>
                                        <td>{key} : &nbsp; <i>{value.SHROOM}</i>&nbsp; <i>{value.xp}</i></td>
                                    </tr>
                                )
                            }
                        )}
                    </th>
                </table>
            </tr>
        </table>
      </div>
  )
}

export default Rewards;