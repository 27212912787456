import React , {useState } from 'react';
import { useTimer } from 'react-timer-hook';

const EpocTime = ({ expiryTimestamp }) => {
// const [epoc, setEpoc] = useState(false)
  const {
    seconds,
    minutes,
    hours,
    days,
  } = useTimer({ expiryTimestamp, onExpire: () => alert("EPOC Time Off !!! Game Over")});

  // if(epoc){
  //   setEpoc(false)
  //   const time = new Date();
  //   time.setSeconds(time.getSeconds() + 10);
  //   restart(time);
  // }

  return (
    <div style={{textAlign: 'center', color: 'green'}}>
      <span>Epoc Countdown time :&nbsp;</span>
      <span> {hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </div>
  );
}

export default EpocTime;