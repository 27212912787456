/* eslint-disable default-case */
import React, {useContext, useEffect, useState} from "react";
import styles from "./Modal.module.css";
import Alice from "../../asserts/alice.png"
import Queen from "../../asserts/queen.png"
import Card from "../../asserts/card.png"
import {CardContext} from '../GameLayout'
import {NotificationManager} from "react-notifications";
import {createRandomNFT} from "../../helper/utils";
import {HexUtils} from 'react-hexgrid';

const ChooseCard = ({chooseCard, setChooseCard, setSelectCard}) => {
  const {
    nfts,
    setNFTs,
    history,
    setHistory,
    addToCardQueue
  } = useContext(CardContext)
  const [allCards, setCards] = useState([]);

  const setCard = (imgSrc, name) => {
    const id = HexUtils.getID(chooseCard.state.hex);
    const current = nfts[id] || {
      "C": null,
      "A": [],
      "Q": []
    }
    const nft = createRandomNFT(name, imgSrc);
    nft['position'] = id;
    if (name === 'C') {
      if (current['C'] === null) {
        current['C'] = nft;
        setNFTs({...nfts, [id]: current})
        addToCardQueue(id)
      } else {
        NotificationManager.error('Already placed a soldier card')
      }
    } else if (name === 'A' || name === 'Q') {
      current[name].push(nft)
      setNFTs({...nfts, [id]: current})
    }

    setHistory([...history, nft])

    setChooseCard(null)
  }

  useEffect(() => {

    console.log(chooseCard);
    var cards = []
    const nftOnHex = nfts[chooseCard.props.data._id_]
    console.log("nftOnHex", nftOnHex)
    if (nftOnHex) {

      if (nftOnHex.C && chooseCard.props.data.blocked) {
        cards.push(nftOnHex.C)
      }
      cards.push(...nftOnHex.A)
      cards.push(...nftOnHex.Q)
      setCards(cards)

      setCards(cards)

      console.log("cards", cards);
    }
  }, [])

  return (
      <>
        <div className={styles.darkBG} onClick={() => setChooseCard(null)}/>
        <div className={styles.centered}>
          <div className={styles.modal}>

            <div className={styles.modalHeader}>
              <h5 className={styles.heading}>Set New Cards.</h5>
            </div>
            <div className={styles.modalContent}>
              <figure>
              <img src={Alice} alt="alice" width="auto" height="auto"
                   onClick={() => setCard(
                       "https://wonder.game/static/media/alice.6b62ce37.png",
                       "A")}/>
                <figcaption className={styles.subHeading}>level: 1-1, Xp: 0</figcaption>
              </figure>
              <figure>
              <img src={Queen} alt="queen" width="auto" height="auto"
                   onClick={() => setCard(
                       "https://wonder.game/static/media/queen.13f2b9cd.png",
                       "Q")}/>
                <figcaption className={styles.subHeading}>level: 1-1, Xp: 0</figcaption>
              </figure>
              {!chooseCard.props.data.blocked &&(
                <figure>
                  <img src={Card} alt="card" width="auto" height="auto"
                       onClick={() => setCard(
                           "https://wonder.game/static/media/card.45d4e49c.png",
                           "C")}/>
                    <figcaption className={styles.subHeading}>level: 1-1, Xp: 0</figcaption>
                </figure>
              )}
            </div>

            <div className={styles.modalHeader}>
              <h5 className={styles.heading}>Choose Card to move</h5>
            </div>
            <div className={styles.modalContent}>
              {allCards.length > 0 ? (
                      allCards.map(card => {
                        console.log("name", card);
                        switch (card.cardName[0]) {
                          case 'A':
                            return (
                              <figure>
                                <img src={Alice} alt="alice" width="100"
                                     height="100"
                                     onClick={() => {
                                       setSelectCard(
                                           {card: card, hex: chooseCard.props.data})
                                       setChooseCard(null)
                                       NotificationManager.info(
                                           'Click on any tile to move card.')
                                     }}/>
                                  <figcaption className={styles.subHeading}>level: {card.level}, Xp: {card.xp}</figcaption>
                                </figure>
                            )
                          case 'Q':
                            return (
                              <figure>
                                <img src={Queen} alt="queen" width="100"
                                     height="100"
                                     onClick={() => {
                                       setSelectCard(
                                           {card: card, hex: chooseCard.props.data})
                                       setChooseCard(null)
                                       NotificationManager.info(
                                           'Click on any tile to move card.')
                                     }}/>
                                <figcaption className={styles.subHeading}>level: {card.level}, Xp: {card.xp}</figcaption>
                              </figure>
                            )
                          case 'C':
                            return (
                              <figure>
                                <img src={Card} alt="card" width="100" height="100"
                                     onClick={() => {
                                       setSelectCard(
                                           {card: card, hex: chooseCard.props.data})
                                       setChooseCard(null)
                                       NotificationManager.info(
                                           'Click on any tile to move card.')
                                     }}/>
                                <figcaption className={styles.subHeading}>level: {card.level}, Xp: {card.xp}</figcaption>
                              </figure>
                            )
                        }
                      })) :
                  <h5 className={styles.subHeading2}>No valid card to move.</h5>
              }
            </div>
          </div>
        </div>
      </>
  );
};

export default ChooseCard;