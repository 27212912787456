export const lastIndexValue = (n) => {
    const arr = [33,28,22,15, 9, 4, 0]
    let number = arr.find(e => e <= n);
    return number;
  }

export const getNeighbour = (hex) => {
    const { q, r, s} = hex;
    const offset = [
        [+1, 0, -1], [+1, -1, 0], [0, -1, +1],
        [-1, 0, +1], [-1, +1, 0], [0, +1, -1]
    ]
    var array = [];
    console.log(q,r,s,offset[0][0]);
    for(var i = 0; i < 6; i++){
        array.push({
            q: q+offset[i][0],
            r: r+offset[i][1],
            s: s+offset[i][2]
        })
    }
    console.log("neighbour : ",array);
    return array;
}


export const getNeighbours = (hex,hexagons) => {
    const { q, r, s} = hex;
    const offset = [
        [+1, 0, -1], [+1, -1, 0], [0, -1, +1],
        [-1, 0, +1], [-1, +1, 0], [0, +1, -1]
    ]
    const neighbours = [];

    for (const neighbour of offset) {
      const _hex = hexagons.find(h => {
        return h.q === q + neighbour[0] && h.r === r + neighbour[1] && h.s === s + neighbour[2]
      })
      if (_hex) {
        neighbours.push(_hex)
      }
    }
    return neighbours;
}

export const hexDistance = (a, b) => {
    return Math.max(Math.abs(a.q - b.q), Math.abs(a.r - b.r), Math.abs(a.s - b.s))
}

export const hasStamina = (stamina, sourceHex, destinationHex) => {
    console.log(stamina, sourceHex, destinationHex)
    const shortestPath = hexDistance(sourceHex, destinationHex);
    return (stamina - shortestPath)
}