const createRandomNFT = (type, imageSrc) => {
  const power = Math.floor(Math.random() * 100) + 1
  const stamina = Math.floor(Math.random() * 20) + 1
  const nameSuffix = (Math.random() + 1).toString(36).slice(2, 6)
  const hash = nameSuffix + "-" + Date.now().toString(16);
  const rewards = 0;
  const xp = 0;
  const level = '1-1';
  return {
    hash,
    power,
    stamina,
    imageSrc,
    cardName: type + '-' + nameSuffix,
    rewards,
    xp,
    level
  }
}

export {
  createRandomNFT
}