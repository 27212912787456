import React, {createContext, useEffect, useRef, useState} from 'react'
import {
  GridGenerator,
  Hexagon,
  HexGrid,
  HexUtils,
  Layout,
  Text
} from 'react-hexgrid';
import styles from "./GameLayout.module.css";
import ChooseCard from "../Modal/ChooseCard"
import EpocTime from '../TimeCountdown/EpocTime';
import History from '../Tables/History'
import Rewards from '../Tables/Rewards'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {getNeighbours, hexDistance} from '../../helper/boardMaths'

import classNames from 'classnames'

export const CardContext = createContext([[], () => {
}])
const GameLayout = () => {
  const [hexagons, setHexagon] = useState([]);
  const [nfts, setNFTs] = useState({});

  const [battleResult, setBattleResult] = useState({});
  const [chooseCard, setChooseCard] = useState(null);

  const [blockedPosition, setBlockedPosition] = useState([])
  const [epocTime, setEpocTime] = useState(null)
  const [history, setHistory] = useState([])
  const [selectCard, setSelectCard] = useState(null);

  const cardQueue = useRef([]);
  const nextReveal = useRef(null)
  const ref = useRef(null)

  const [battle, setBattle] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      nextReveal.current = cardQueue.current[0]?.id
      for (let [index, card] of cardQueue.current.entries()) {
        const remainingTime = card.timeRemaining - 1
        console.log('remainingTime : ', remainingTime)
        if (remainingTime <= 0) {
          cardQueue.current.splice(index, 1)
          revealHex(card.id);
        } else {
          cardQueue.current[index].timeRemaining = remainingTime
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [hexagons, nfts])

  const onClick = (event, source) => {
    console.log(selectCard, source);
    if (selectCard) {
      const shortestDistance = hexDistance(selectCard.hex, source.state.hex);
      const id = source.props.data._id_;
      const previousId = HexUtils.getID(selectCard.hex)
      const nftOnSourceHex = nfts[id] || {
        "C": null,
        "A": [],
        "Q": []
      }
      const nftOnOldHex = nfts[previousId]
      const type = selectCard.card.cardName[0]
      selectCard.card.position = id;
      if (type === 'C') {
        if (nftOnSourceHex['C'] === null) {
          nftOnSourceHex['C'] = selectCard.card;
          nftOnOldHex['C'] = null;
          setNFTs({...nfts, [id]: nftOnSourceHex, [previousId]: nftOnOldHex})
          if (!source.props.data.number) {
            addToCardQueue(id)
          }

        } else {
          NotificationManager.error('Already placed a soldier card')
        }

      } else if (type === 'A' || type === 'Q') {
        nftOnSourceHex[type].push(selectCard.card)
        nftOnOldHex[type] = nftOnOldHex[type].filter(c => {
          return c.hash !== selectCard.card.hash
        })
        setNFTs({...nfts, [id]: nftOnSourceHex, [previousId]: nftOnOldHex})
      }
      setHistory(history.map(h => {
        if (h.hash === selectCard.card.hash) {
          h.position = id
        }
        return h
      }))
      setChooseCard(null)
      setSelectCard(null)
    } else {
      // Open model
      // if (source.props.data.blocked) {
        setChooseCard(source)
      // } else {
        // setSourceHex(source.state.hex)
        // setIsOpen(true)
      // }
    }
  }

  const addToCardQueue = (id) => {
    nextReveal.current = id;
    cardQueue.current.push({id, timeRemaining: 4})
    // setCards(cardQueue.current)
  }

  const revealHex = (id) => {
    const _hexagons = hexagons.map((hex) => {
      if (hex._id_ === id) {
        hex.number = hex._value_
        console.log("ID :", id, "value :", hex.number);
        hex.blocked = true;
        revealTile(hex)
      }
      return hex
    })
    setHexagon(_hexagons);
  };

  const revealTile = (_hex) => {
    const _nfts = {...nfts};
    const _rewards = {"C": {}, "Q": {}, "A": {}}
    const battle = {
      tile: _hex._id_,
      number: _hex.number,
      Tx_hash: '',
      alices: {}
    }
    battle.Tx_hash = Date.now().toString(16).slice(-1);

    //Finding all QA cards

    var allQueens = []
    var allAlices = []
    Object.values(_nfts).forEach(({Q, A}) => {
      allQueens.push(...Q)
      allAlices.push(...A)
    })

    //SHROOM Reward
    let baseReward = 300;
    if (_hex.number !== 'S') {
      if (_hex.number === 0) {
        baseReward = 100
      } else {
        baseReward = 150 + (20 * (_hex.number - 1))
      }
    }

    //SHROOM Rewards
    var soldierReward = 0.7 * baseReward
    var soldierXpReward = 0.5 * soldierReward
    const taxPortion = 0.3 * baseReward
    setBlockedPosition([...blockedPosition, _hex])
    const current = _nfts[_hex._id_];

    battle.queens = current['Q'].map(q => q.hash)
    const contenderQueens = current['Q'].filter(
        q => q.hash.slice(-1) === battle.Tx_hash)
    const availableAlices = [...current['A']]
    battle.alices['self'] = current['A'].map(a => a.hash)
    const neighbours = getNeighbours(_hex, hexagons);

    neighbours.forEach(neighbour => {
      if (_nfts[neighbour._id_]) {
        const alices = _nfts[neighbour._id_].A
        battle.alices[neighbour._id_] = alices.map(a => a.hash)
        availableAlices.push(...alices)
      }
    })

    const contenderAlices = availableAlices.filter(
        a => a.hash.slice(-1) === battle.Tx_hash)

    let queens_pool = 0;
    let alices_pool = 0;

    let battle_contender_queen_portion = 0;
    let battle_contender_alice_portion = 0;

    const isContenderAlicesExist = contenderAlices.length !== 0;
    const isContenderQueensExist = contenderQueens.length !== 0;

    //find rewards for soldier cards.
    if(allQueens.length === 0 && !isContenderAlicesExist){  //Check if there is queen pool
      soldierReward = baseReward
      soldierXpReward = 0.5 * soldierReward
    }
    const cardHash = current.C.hash
    _rewards["C"][cardHash] = {
      'SHROOM' :parseFloat(soldierReward.toFixed(2)),
      'xp' : parseFloat(soldierXpReward.toFixed(2))
    }
    //Update card nft stats
    const {xp, level} =  getUpdatedLevel(current.C.level, current.C.xp + _rewards["C"][cardHash]['xp'])
    _nfts[_hex._id_]['C'].rewards += _rewards["C"][cardHash]['SHROOM']
    _nfts[_hex._id_]['C'].xp = xp
    _nfts[_hex._id_]['C'].level = level


    if (!isContenderAlicesExist && !isContenderQueensExist) {
      queens_pool = taxPortion

    } else if (!isContenderAlicesExist && isContenderQueensExist) {
      queens_pool = taxPortion * 0.95
      battle_contender_queen_portion = (taxPortion * 0.05)
          / contenderQueens.length;

    } else if (isContenderAlicesExist && !isContenderQueensExist) {
      alices_pool = taxPortion * 0.95
      battle_contender_alice_portion = (taxPortion * 0.05)
          / contenderAlices.length;

    } else if (isContenderAlicesExist && isContenderQueensExist) {
      const battle_winner_portion = taxPortion * 0.2
      const tax_distribution_portion = taxPortion - battle_winner_portion;

      const queenPower = contenderQueens.reduce((accumulator, q) => {
        return accumulator + q.power;
      }, 0);

      const alicePower = contenderAlices.reduce((accumulator, q) => {
        return accumulator + q.power;
      }, 0);

      let queens_pool_portion = tax_distribution_portion * queenPower
          / (queenPower + alicePower)

      let alices_pool_portion = tax_distribution_portion - queens_pool_portion

      if (queenPower > alicePower) {
        queens_pool_portion += battle_winner_portion

      } else if (queenPower < alicePower) {
        alices_pool_portion += battle_winner_portion
      } else {
        //TODO
      }
      alices_pool = alices_pool_portion * 0.95
      queens_pool = queens_pool_portion * 0.95

      battle_contender_alice_portion = (alices_pool_portion * 0.05)
          / contenderAlices.length;
      battle_contender_queen_portion = (queens_pool_portion * 0.05)
          / contenderQueens.length;

    }
    contenderAlices.forEach(a => {
      _rewards["A"][a.hash] = {
        'SHROOM': (`${a.hash}` in _rewards["A"] ? _rewards["A"][a.hash]['SHROOM'] : 0) + battle_contender_alice_portion,
        'xp':(`${a.hash}` in _rewards["A"] ? _rewards["A"][a.hash]['xp'] : 0) + ( 0.5 * battle_contender_alice_portion )
      }
    })

    contenderQueens.forEach(q => {
      _rewards["Q"][q.hash] = {
        'SHROOM': (`${q.hash}` in _rewards["Q"] ? _rewards["Q"][q.hash]['SHROOM'] : 0) + battle_contender_queen_portion,
        'xp': (`${q.hash}` in _rewards["Q"] ? _rewards["Q"][q.hash]['xp'] : 0) + (0.5 * battle_contender_queen_portion )
      }
    })

    const individual_alice_portion = alices_pool / allAlices.length
    const individual_queen_portion = queens_pool / allQueens.length

    var AlicesRewards = 0
    var QueensRewards = 0
    allQueens = allQueens.map(q => {
      _rewards["Q"][q.hash] = {
        'SHROOM': parseFloat(((`${q.hash}` in _rewards["Q"] ? _rewards["Q"][q.hash]['SHROOM'] : 0) + individual_queen_portion).toFixed(2)),
        'xp': parseFloat(((`${q.hash}` in _rewards["Q"] ? _rewards["Q"][q.hash]['xp'] : 0) +(0.5 * individual_queen_portion)).toFixed(2))
      }
      QueensRewards +=  _rewards["Q"][q.hash]['SHROOM']

      //to update reward in actual card
      const {xp, level} =  getUpdatedLevel(q.level, q.xp + _rewards["Q"][q.hash]['xp'])
      return {...q, 
        rewards: q.rewards + _rewards["Q"][q.hash]['SHROOM'],
        xp: xp,
        level: level
      };
    })

    allAlices = allAlices.map(a => {
      _rewards["A"][a.hash] = {
        'SHROOM': parseFloat(((`${a.hash}` in _rewards["A"] ? _rewards["A"][a.hash]['SHROOM'] : 0) + individual_alice_portion).toFixed(2)),
        'xp': parseFloat(((`${a.hash}` in _rewards["A"] ? _rewards["A"][a.hash]['xp'] : 0) + (0.5 * individual_alice_portion)).toFixed(2))
      }
      AlicesRewards += _rewards["A"][a.hash]['SHROOM']

      //to update reward in actual card
      const {xp, level} =  getUpdatedLevel(a.level, a.xp + _rewards["A"][a.hash]['xp'])
      return {...a, 
        rewards: a.rewards + _rewards["A"][a.hash]['SHROOM'],
        xp: xp,
        level: level
      };
    })
    _rewards["QueensRewards"] = QueensRewards.toFixed(2)
    _rewards["AlicesRewards"] = AlicesRewards.toFixed(2)
    console.log({
      [_hex._id_]: {
        ...battle,
        rewards: _rewards
      }
    })
    setBattleResult({
      ...battleResult, [_hex._id_]: {
        Results: battle,
        Rewards: _rewards
      }
    })

    UpdateNftHistory(_hex._id_, _nfts, allAlices, allQueens)
  }

  const getXpRequirement = (potential, grade) => {
    const baseXp = 60;
    const exponential = 0.05 * grade;
    const fromBase = potential * baseXp;
    const fromExponential = Math.pow(fromBase, exponential);
    return Math.floor(fromBase + fromExponential);
  }

  const getUpdatedLevel = (level, xp) => {
    var potential = Number(level.split("-")[0]);
    var grade = Number(level.split("-")[1]);
    var xpRequirement = getXpRequirement(potential, grade);
    
    while(xp >= xpRequirement){
      xp -= xpRequirement
      grade += 1
      if(grade > 12){
        potential += 1
        grade -= 12
      }
      xpRequirement = getXpRequirement(potential, grade);
    }
    return {
      xp: xp,
      level: `${potential}-${grade}`
    }
  }

  const UpdateNftHistory = (hexId, _nfts, allAlices, allQueens) => {
    //Update reward and xp data in QA nft cards
    allAlices.forEach(alice => {
      const AlicesPositions = Object.keys(_nfts).map(id => {
        return ({
                _id: id, 
                _index: _nfts[id]['A'].findIndex(card => card.hash === alice.hash)
              })
      })
      AlicesPositions.forEach(({_id, _index}) => {
        if (_index !== -1){
          _nfts[_id]['A'][_index] = alice
        }
      })
    })

    allQueens.forEach(queen => {
      const QueensPositions = Object.keys(_nfts).map(id => {
        return ({
                _id: id, 
                _index: _nfts[id]['Q'].findIndex(card => card.hash === queen.hash)
              })
      })
      QueensPositions.forEach(({_id, _index}) => {
        if (_index !== -1) {
          _nfts[_id]['Q'][_index] = queen
        }
      })
    })
    setNFTs(_nfts)

    //Set History
    Object.keys(_nfts).forEach(id => {
      _nfts[id]['A'].forEach(card => {
        setHistory(history.map(h => {
          if (h.hash === card.hash) {
            h.level = card.level
            h.rewards = card.rewards
            h.xp = card.xp
          }
          return h
        }))
      })
      _nfts[id]['Q'].forEach(card => {
        setHistory(history.map(h => {
          if (h.hash === card.hash) {
            h.level = card.level
            h.rewards = card.rewards
            h.xp = card.xp
          }
          return h
        }))
      })
      setHistory(history.map(h => {
        if(h.hash === _nfts[hexId]['C'].hash){
          h.level = _nfts[hexId]['C'].level
          h.rewards = _nfts[hexId]['C'].rewards
          h.xp = _nfts[hexId]['C'].xp
        }
        return h
      }))
    })
    
  }

  useEffect(() => {
    if (ref.current == null) {
      const _hexagons = GridGenerator.hexagon(3);
      const length = _hexagons.length;
      let numberOfS = length / 2
      while (numberOfS > 0) {
        const _index = Math.floor(Math.random() * length);
        _hexagons[_index]._value_ = 'S'
        numberOfS--;
      }
      ref.current = true;
      setHexagon(_hexagons.map(hex => {
        if (hex._value_ === undefined) {
          const neighbours = getNeighbours(hex, _hexagons);
          hex._value_ = neighbours.filter(h => h._value_ === 'S').length
        }
        hex._id_ = HexUtils.getID(hex);
        return hex;
      }))
    }
  }, [])

  useEffect(() => {
    const time = new Date();
    // setRevealTime(time.setSeconds(time.getSeconds() + 5));
    setEpocTime(time.setSeconds(time.getSeconds() + 1115));
  }, [])

  return (
      <CardContext.Provider value={{
        history,
        setHistory,
        nfts,
        setNFTs,
        addToCardQueue
      }}>
        <div className={styles.flexContainer}>
          <div>
            {battle && <Rewards rewards={battle} />}
            <History history={history}/>
            {/* {battle && <pre>{JSON.stringify(battle, null, 2)}</pre>} */}
          </div>
          <div>
            <div style={{textAlign: 'center'}}>Next Revealing Tile : {nextReveal.current}</div>
            {epocTime && <EpocTime expiryTimestamp={epocTime}/>}
            <HexGrid width={650} height={650}>
              <Layout className={styles.game} size={{x: 8, y: 8}}>
                {hexagons.map((hex, i) => (
                    <Hexagon key={i} q={hex.q} r={hex.r} s={hex.s}
                             className={classNames({
                               [styles.blocked]: hex.blocked,
                               [styles[`hex-${hex.number}`]]: true
                             })}
                        // fill={(hex.image) ? HexUtils.getID(hex) : null}
                             data={hex}
                             onClick={(e, h) => onClick(e, h)}
                             onMouseOver={(e, h) => {
                               setBattle(battleResult[h.props.data._id_])
                             }}
                    >
                      {!!hex.number ? <Text>{`${hex.number}`}</Text> :
                          <Text></Text>}
                      {/* { !!hex.number ? (<Text>{`${hex.number},`}{hex.card ? `${hex.card.name}`:null}{hex.QAcard? hex.QAcard.map(QA => {return QA.name}): null}</Text>):(<Text>{Math.abs(hex.q) < 2 ? 1+(i-lastIndexValue(i)):2+(i-lastIndexValue(i))},{hex.q + 4}{hex.card ? `${hex.card.name}`:null}{hex.QAcard? hex.QAcard.map(QA => {return QA.name}): null}</Text>)} */}
                      {/*{!!hex.number ? (*/}
                      {/*    <Text>{`${hex.number},`}{hex.card ? `${hex.card.name}`*/}
                      {/*        : null}{hex.QAcard ? hex.QAcard.map(QA => {*/}
                      {/*      return QA.name*/}
                      {/*    }) : null}</Text>) : (*/}
                      {/*    <Text>{hex.card ? `${hex.card.name}`*/}
                      {/*        : null}{hex.QAcard ? hex.QAcard.map(QA => {*/}
                      {/*      return QA.name*/}
                      {/*    }) : null}</Text>)}*/}
                      {/* { !!hex.image && <Pattern id={HexUtils.getID(hex)} link={hex.image} /> } */}
                    </Hexagon>
                ))
                }
              </Layout>
            </HexGrid>
          </div>
          {chooseCard && <ChooseCard chooseCard={chooseCard}
                                     setSelectCard={setSelectCard}
                                     setChooseCard={setChooseCard}/>}
          <NotificationContainer/>
        </div>
      </CardContext.Provider>
  )
}

export default GameLayout;
